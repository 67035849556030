* {
    transition: all 0.2s ease;
}

.sub-text {    font-size: 25px;}

#DAppArea {background: #000000d1;    padding: 20px;    border-radius: 9px;}

#workingTable table {    margin-left: auto;
    margin-right: auto;
    background: #42641ac2;
    padding: 20px;
    border-radius: 9px;}

.red { color:red; }
.blue { color:#353f9c; }
.green { color:green;}
.white { color:white; }
.black { color:black; }

#walletInput {    border: 4px solid #353f9c;
    border-radius: 9px;
    padding: 10px;
    margin-top: 20px;
    width: 375px;
    background: #f8f7fc;
    font-size: 15px;
    font-weight: bold;
    text-align: center;  color: #1f6131;}

div.clear {    background: none;    border: 0;    clear: both;    display: block;    float: none;    font-size: 0;    list-style: none;    margin: 0;    padding: 0;    overflow: hidden;    visibility: hidden;    width: 0;    height: 0; }

@font-face { font-family: 'Oswald'; src: local('Oswald'), url('Oswald-VariableFont_wght.ttf') format('truetype'); }

.buttonMessage{    font-size: 70%;  font-family: sans-serif; color: #a16b09; }
.buttonMessage a {color: #a16b09; text-decoration: underline;}

body { 
    /* background by SVGBackgrounds.com */
    background-color: #240606;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2313031a'/%3E%3Cstop offset='1' stop-color='%2313031a' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23120303'/%3E%3Cstop offset='1' stop-color='%23120303' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23010017'/%3E%3Cstop offset='1' stop-color='%23010017' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23240606'/%3E%3Cstop offset='1' stop-color='%23240606' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2302002D'/%3E%3Cstop offset='1' stop-color='%2302002D' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23000000'/%3E%3Cstop offset='1' stop-color='%23000000' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    color: whitesmoke; margin: 0; padding: 0; font-family: 'Oswald', sans-serif!important; }

a { color: #99a9a8; text-decoration: none; }

.noCollectionSpacer { height:30px; }

.smaller { font-size: 80%; }

.fixcaps { text-transform: initial !important; }

div.GridItem {
    align-self: center;
    @media(max-width: 765px) {
        margin-bottom: 1rem;
    }
}

div.Welcome {
    text-transform: uppercase;
    font-size: 3vw;
    @media(max-width: 800px) {
        font-size: 4vw;
    }
}

div.MaxPerWallet {
    color: rgb(255 255 255 / 60%);
    font-size: 1.1rem;
    @media (max-width: 764px) {
        font-size: 4vw;
    }
}

div.PriceWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

div.PriceText {
    font-size: 2vw;
    @media(max-width: 764px) {
        font-size: 4vw;
    }
}

div.AmountWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

button.CounterBtnWrap {
    border: 0;
    background: transparent;
    color: white;
    font-size: 4vw;
    cursor: pointer;
    @media (max-width: 764px) {
        font-size: 10vw;
    }
}

div.CounterInput {
    background: transparent;
    border: solid white 2px;
    text-align: center;
    width: 15%;
    margin-right: 2rem;
    margin-left: 2rem;
    font-size: 1rem;
    padding: 1rem;
    @media( max-width: 764px) {
        width: 30%;
    }
}

input.slider {}

div.MintBtn {
    margin: 2rem 0;
    background: #d94745 !important;
    color: #0F0F0F !important;
    cursor: pointer;
    text-transform: uppercase;
    justify-self: self-start;
    border: 0 !important;
    font-size: 1.5vw;
    transition: all .2s;
}

.MintBtn:hover {
    color: white !important;
    background: black !important;
    transition: all .2s;
}

div.SoldFont {
    font-size: 1.4rem;
    color: white;
    @media (max-width: 765px) {
        font-size: 2rem;
    }
}

img.CollectionImage {
    width: 65%;
    border-radius: 1rem;
}

div.LoadingFont {
    font-size: 1.5vw;
    color: white;
}

div.CountDownTimer {}

#pageLogo img {
    width: 100%;
}

#pageLogo {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 15px;
    left: 9%;
}



#saleStatus {
    font-size: 150%;
    color: #fff;
    text-decoration: overline;
    text-decoration-color: #00ffa7;
    text-decoration-thickness: from-font;
    text-decoration-style: wavy;
}

#theStatus {
    padding-left: 15px;
    color: white;
}

.pageHighlightColor {
    color: #efde0e;
}

.pageHighlightColor2 {
    color: #6a624a;
}

#branding {
    padding-top: 20px;
}

#branding img {
    border: 2px solid black;
    -webkit-box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
    -moz-box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
    box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
}

#navButtons {
    margin-top: 20px;
}

img.navIcons {
    position: relative;
    top: 3px;
    left: -7px;
    width: 20px;
    height: 20px;
}

.logo-wallet {
    width: 90px;
    height: 90px;
}
.walletName{

    font-family: 'Oswald';
    font-weight: 700;
    font-size: 200%;
    vertical-align: top;

}

#DAppArea button { margin-top:15px;  }
#DAppArea p {font-size: 120%;}

#totalPrice { margin-top:15px; margin-bottom:15px;  }
#prepare button, #DAppArea button { background: #353f9c;    color: white;    border: initial;    padding: 10px 20px 10px 20px;    border-radius: 9px;    text-transform: uppercase;    font-family: 'Oswald';    font-size: 110%; }
#prepare button:hover, #DAppArea button:hover { background: #000;  }

div.ReactModal__Content.ReactModal__Content--after-open {    background: #353f9c !important;    text-align: center; border: 5px solid black !important;  border-radius: 9px !important; padding-top: 10px !important; }
div.ReactModal__Content.ReactModal__Content--after-open h2 {  color: #f68614 !important;     font-size: 160%;    letter-spacing: 2px;    margin-bottom: 0;    margin-top: 0; }
div.ReactModal__Content.ReactModal__Content--after-open button {    background: white;    border: initial;    padding: 5px;    border-radius: 5px;     margin-bottom: 15px; }
div.ReactModal__Content.ReactModal__Content--after-open .providerChoices {     width: 100%;  margin-bottom: 10px; background: #fff;  border: 3px solid black;    border-radius: 9px;    
    margin-top: 10px; }

.topBanner img { max-width: 360px; width: 100%; }
.topBanner {  padding: 0px;        padding-bottom: 0;  text-align: center;  width: 90%;
    margin-left: auto;
    margin-right: auto;  }
.topBannerWrapper { max-width: 1200px;    margin-left: auto;    margin-right: auto; padding-top:20px; }
.topBannerLeft { float: left; width: 30%; display:none; }
.topBannerRight { width: 70%;    float: right;    margin: 0;    padding: 0;    text-align: right;  }
.topBannerRight a { color:whitesmoke; padding-left:30px; font-size: 130%; }
.topBannerRight a:hover { color:#51381a; }
.topBannerButton {    padding: 3px 10px 3px 10px;    border-radius: 5px; box-shadow: 3px 3px 11px rgb(0 0 0 / 40%);    -webkit-box-shadow: 3px 3px 11px rgb(0 0 0 / 40%);  -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    white-space: nowrap;    background: black;    margin: 20px 20px 20px 20px; }
.topBannerButton:hover {  background: #d54aff; }

#DAppArea h2 {    color: #efde0e;    font-size: 300%;    margin-top: 0;    font-weight: 100; }

#DAppArea h3 {    color: #efde0e;    font-style: italic;    font-size: 150%;     font-weight: 100; }

#walletButton {}

.qty { color: rgb(184, 194, 128); }

.welcome {
    text-align: center;
    font-size: 135%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 30px;
    MARGIN-TOP: 0;
    letter-spacing: 1px;
}

span.copyrightNotice {
    font-size: 65%;
}

div.mintBoxWrapper {
    background: #1c1c1c;
    width: 90%;
    display: flex;
    overflow: auto;
    margin: 15px auto 20px auto;
    max-width: 1200px;
    background-image: url(background.jpg) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border-top-right-radius: 10px;
}

div.mintBoxLeft {
    width: 40%;
    padding: 20px;
    float: left;
    text-align: center;
    background:#000000f2;
    /*background-image: url(towerleft.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-repeat-y: repeat;*/
}

div.mintBoxLeft img {  width: 100%; }


div.mintBoxRight {
    width: 100%;
    padding: 20px;
    float: left;
    text-align: center;
    padding: 2%;
}

.mobileOnly {
    display: none;
}

#currentMintCount {}

/* YOUR COLLECTION */

.collectionWrapper {
    padding: 1% 2% 3% 2%;
    background: #1c1c1c;
    text-align: center;
    background: black;
    background-image: url(https://yearofthewoman.io/wp-content/uploads/2022/02/4907157-scaled.jpg?id=251) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

input.pageButton span {}

.collectionWrapper h2 {
    font-size: 225%;
}

.collectionRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    margin-top: 2rem;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
}

#renderCollection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    margin-top: 2rem;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
}

.collectionItem {}

.collectionImage {
    width: 75%;
    border-radius: 1rem;
    vertical-align: middle;
    border: 4px solid #51381a;
}

.collectionTokenTitle {
    margin-top: 1rem;
    color: #51381a;
    font-size: 125%;
}

.header {
    width: 100%;
    background: #778384;
    padding: 0;
    text-align: center;
    padding-bottom: 9px;
    padding-top: 5px;
}

.footer {

    box-shadow: 0px 0px 38px 10px #f5f4eda1;
    border-top: 2px solid white;


    width: 100%;
    background: #18260c;
    padding: 0;
    text-align: center;
    color: whitesmoke;
    padding-bottom: 8px;
    padding-top: 7px;

    bottom : 0px;  
    height : 40px;  
    margin-top : 40px;  
    text-align: center;  
    vertical-align: middle; 
    position:fixed;
    width:100%;

}

a.pageButton {
    background: #002771;
    color: white;
    padding: 4px 2% 4px 2%;
    margin: 0 1% 0 1%;
    line-height: 250%;
    border-radius: 5px;
    box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
}

a.pageButton:hover {
    background: black;
    color: #778384;
}

.bannerButton {
    padding: 9px 13% 8px 13%;
    line-height: 250%;
    border-radius: 5px;
    box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
}

.bannerButton:hover {
    background: #d54aff;
}

#HeaderButtons {
    margin-top: 20px;
    position: absolute;
    top: 1%;
    right: 6%;
}

.header img {
    position: relative;
    top: 3px;
    left: -4px;
    width: 20px;
}

.footer img {
    position: relative;
    top: 3px;
    left: -4px;
    width: 20px;
}

.credits {
    background: #778384;
    text-align: center;
    font-size: 80%;
    letter-spacing: 1px;
    color: white;
}

.credits a {
    color: whitesmoke;
}

.larger {
    font-size: 200%;
    font-weight: 100;
    letter-spacing: initial;
}

input[type=range] {
    width: 30%;
    margin: 8px 0;
    background-color: transparent;
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    background: #f8f7fc;
    border: 1px solid #353f9c;
    border-radius: 25px;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
    margin-top: -9px;
    width: 33px;
    height: 22px;
    background: #f8f7fc;
    border: 4px solid #353f9c;
    border-radius: 9px;
    cursor: pointer;
    -webkit-appearance: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #f8f7fc;
}

input[type=range]::-moz-range-track {
    background: #f8f7fc;
    border: 1px solid #353f9c;
    border-radius: 25px;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-moz-range-thumb {
    width: 33px;
    height: 22px;
    background: #f8f7fc;
    border: 4px solid #353f9c;
    border-radius: 9px;
    cursor: pointer;
}

input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 12.1px 0;
    color: transparent;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-ms-fill-lower {
    background: #f8f7fc;
    border: 1px solid #353f9c;
    border-radius: 50px;
}

input[type=range]::-ms-fill-upper {
    background: #f8f7fc;
    border: 1px solid #353f9c;
    border-radius: 50px;
}

input[type=range]::-ms-thumb {
    width: 33px;
    height: 22px;
    background: #f8f7fc;
    border: 4px solid #353f9c;
    border-radius: 9px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}

input[type=range]:focus::-ms-fill-lower {
    background: #ffffff;
}

input[type=range]:focus::-ms-fill-upper {
    background: #ffffff;
}

.bottomMessageMobile { display: none; }

@media (max-width: 800px) {
    div.mintBoxLeft {
        width: 0%;
        padding: 0;display: none;
    }
    div.mintBoxRight {
        width: 90%;
        padding: 20px;
    }
    .mobileOnly {
        display: block;
        margin: 10px auto 20px auto;
        max-width: 200px;
    }
    .bottomMessageMobile {
        display: block;
        margin: 10px auto 20px auto;
    }

    #renderCollection {
        grid-template-columns: 1fr 1fr;
    }
    #amountSold {}
    .topBanner { min-height: 80px; }

    .footer { display:none; }

    #walletInput { width:initial; }

    .topBannerRight a { padding-left: 0; width: 100%; display: block; }
    #workingTable table { padding: 10px 0px 0px 0px; }
    
}

@media (min-width: 1400px) {
    #renderCollection {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 500px) { 

   .topBanner { min-height: 50px; }
   .topBanner img { display:none; }
   .topBannerLeft { display:none; }
   .topBannerRight { width: 100%;  float: initial; text-align: center; }
   .header img, .footer img { display:none; }

}
